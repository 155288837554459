:root {
  --primary-blue-dark: #203973;
  --primary-blue: #284790;
  --primary-dark-grey: #4f4f4f;
  --primary-green: #496e00;
  --primary-grey: #e1e1e1;
  --secondary-grey: #bdbdbd;
  --primary-red-hover: #f8baba;
  --primary-green-hover: #b8ddb5;
  --primary-red: #991d1d;
  --secondary-blue: #0085ad;
  --secondary-dark-grey: #363636;
  --secondary-green: #69a141;
  --secondary-red: #c70c0c;
  --secondary-gold: #ffc845;
  --banner-yellow: #f2c94c;
  --banner-yellow-light: rgb(242 201 76 / 30%);
  --banner-red: rgb(235 87 87 / 30%);
  --banner-red-hover: rgb(235 87 87 / 65.8%);
  --banner-green: rgb(117 176 112 / 30%);
  --main-button-disabled: #828282;
  --visited-link-blue: #00799e;
  --refactored-primary-grey: #f4f4f4;
  --refactored-primary-blue: var(--accent-800);
  --surface-50: rgb(244 244 244);
  --surface-75: rgb(249 249 249);
  --surface-100: rgb(255 255 255);
  --surface-200: rgb(244 244 244);
  --surface-300: rgb(234 234 234);
  --surface-400: rgb(211 211 211);
  --surface-500: rgb(188 188 188);
  --surface-600: rgb(149 149 149);
  --surface-700: rgb(116 116 116);
  --surface-800: rgb(80 80 80);
  --surface-900: rgb(50 50 50);
  --accent-400: rgb(81 119 207);
  --accent-500: rgb(50 89 180);
  --accent-600: rgb(40 71 144);
  --accent-700: rgb(32 57 115);
  --accent-800: rgb(26 46 92);
  --red-400: rgb(240 92 78);
  --red-500: rgb(219 84 71);
  --red-600: rgb(199 80 68);
  --red-700: rgb(172 74 63);
  --red-800: rgb(149 68 58);
  --green-400: rgb(102 156 65);
  --green-500: rgb(95 141 63);
  --green-600: rgb(88 129 60);
  --green-700: rgb(80 114 57);
  --green-800: rgb(72 100 52);
  --orange-400: rgb(194 126 67);
  --orange-500: rgb(174 116 64);
  --orange-600: rgb(160 107 62);
  --orange-700: rgb(140 95 57);
  --orange-800: rgb(122 85 63);
  --light-accent-400: rgb(78 148 192 / 10%);
  --light-accent-500: rgb(78 148 192 / 15%);
  --light-accent-600: rgb(78 148 192 / 25%);
  --light-accent-700: rgb(78 148 192 / 40%);
  --light-accent-800: rgb(78 148 192 / 60%);
  --light-red-400: rgb(240 92 78 / 10%);
  --light-red-500: #f3d5cd;
  --light-red-600: rgb(240 92 78 / 25%);
  --light-red-700: rgb(240 92 78 / 40%);
  --light-red-800: rgb(240 92 78 / 60%);
  --light-green-400: rgb(102 156 65 / 10%);
  --light-green-500: rgb(102 156 65 / 15%);
  --light-green-600: rgb(102 156 65 / 25%);
  --light-green-700: rgb(102 156 65 / 40%);
  --light-green-800: rgb(102 156 65 / 60%);
  --light-orange-400: rgb(194 126 67 / 10%);
  --light-orange-500: rgb(194 126 67 / 15%);
  --light-orange-600: rgb(194 126 67 / 25%);
  --light-orange-700: rgb(194 126 67 / 40%);
  --light-orange-800: rgb(194 126 67 / 60%);
  --trans-white-400: rgb(255 255 255 / 10%);
  --trans-white-500: rgb(255 255 255 / 15%);
  --trans-white-600: rgb(255 255 255 / 25%);
  --trans-white-700: rgb(255 255 255 / 40%);
  --trans-white-800: rgb(255 255 255 / 60%);
  --main-blue: #0085ad;
  --banner-blue: #00577b;
  --main-green: #75b070;
  --main-green-hover: #b8ddb5;
  --main-blue-hover: #2db9e0;
  --nav-bar-blue: #0089b0;
  --main-red: #eb5757;
  --main-red-hover: #f8baba;
}
